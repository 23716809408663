<template>

    <div class="container H_100vh bg_fff">
        <div class="flex_row_between PLR_3 PTB_4">
            <van-icon name="arrow-left" @click="$router.go(-1)" size=".35rem" />
        </div>
        <div class="PLR_5 textL PB_4 PT_2 fontS_17 font_weight_bold">
            请选择所要添加的技能类型
        </div>
        <div class="flex_rowC PLR_5 PB_4 relative">
            <input   @input="text($event)"  type="text" style="width:50%" class="PTB_2 B_1_F44C06 PL_4 B_radius_5 flex_grow_1" placeholder="请输入内容">
            <van-icon name="search" size=".4rem" class="absolute color_F44C06" style="right: 10%" />
        </div>
       
    <div v-if="showLoading == false">
      <div v-if="filterList.length == 0">
        <div class="D_flex color_686868">
              <van-sidebar  v-model="activeKey">
                <van-sidebar-item @click="viewList(index)" v-for="(item, index) in allList" :key="index" :title="item.position_name" />
              </van-sidebar> 
            
            <div class="rel flex_grow_1 W_60 flex_rows_start PT_1">
                <div @click="getName(item1.position_name,index,item1.positionId)" :class="item1.status?'txtColor':' ' " class="W_50 textC PTB_3" v-for="(item1,index) in list2" :key="index">
                    {{item1.position_name}}
                </div> 
                <div @click="edit()" class="next">编辑</div>
              </div> 
            
        </div>
      </div>
      <div  class="list" v-else>
        <div :class="item1.status?'active':'noactive' " @click="searchMsg(item1,index)"  v-for="(item1,index) in filterList" :key="index">{{item1.position_name}}</div>
        <div class="addtel" @click="edit">编辑</div>
      </div>
    </div>
     <van-loading v-if="showLoading" size="45" class="loading" type="spinner" />

   </div>
</template>
<script>
import '@/assets/css/common.css'; 
import { Toast } from 'vant';

export default {
  data(){
    return {
      activeKey:0,
      allList:[],
     // list:[],  // 一级技能列表
      list2:[], // 二级技能列表
      name:"",
      skill_id:"",
      filterList:[], //搜索列表
      obj:{},
      positionId:"",
      showLoading:false
       
    }
  },
  methods:{
    // add1(){
    //   let id = sessionStorage.getItem("id")
    //   let obj = this.obj
    //   console.log(id, obj)
    //   this.$http.post("/user/v1/Position/userSkill",{reqType:"userSkill",user_id:id,skill_type:obj.type,skill_name:obj.skill_name,mim_id:0,status:1,skill_id:obj.skill_id})
    //     .then((res)=>{ 
    //       res = JSON.parse(res.data)
    //     if(res.code == 0){
    //       Toast.success(res.msg)
    //       this.filterList = []
    //       this.$router.push("./onlineTag")
    //     }
    //     })
    //     .catch((e)=>{console.log(e)})
    // },
    searchMsg(item,index){
      console.log(item)
      this.positionId = item.positionId
      this.name = item.position_name
      let obj = {}
      obj.type = this.$route.query.type
      obj.user_id = sessionStorage.getItem("user_id")
      obj.skill_name = item.position_name
      obj.skill_id = item.positionId
      this.obj = obj
      
      let filterList = this.filterList
      
      for(var i=0;i<filterList.length;i++){
        if(index == i){
          filterList[index].status = true
        }else{
           filterList[i].status = false
        }
      }
       this.filterList = filterList
      // console.log(this.filterList)
      // this.filterList = filterList
    },
    text(e){
      let data = e.data
      let allList = this.allList
      // console.log(allList,e)
      let arr = []
     for(var i=0;i<allList.length;i++){
       if(allList[i].children){
        for(var j=0;j<allList[i].children.length;j++){
         if(allList[i].children[j].position_name.indexOf(data) >= 0) {
           arr.push(allList[i].children[j])
         }
       }
       }
       
     }
     arr.map((val)=>{
       val.status = false
       return val
     })
    //  console.log(arr)
     this.filterList = arr
     
    },
    viewList(index){
      let allList = this.allList
      let skill_id = allList[index].positionId
      let name = allList[index].position_name
      this.name = name
      this.skill_id = skill_id
      // console.log(skill_id)
      let list2 = allList[index].children
      if(list2){
        list2.map((item)=>{
        item.status = false
        return item
      })
      }
      
      // console.log(list2)
      this.list2 = list2
    },
    getName(name,index,positionId){
      this.positionId = positionId
      //技能名称
      this.name = name
      let list2 = this.list2
      for(var i=0;i<list2.length;i++){
         if(index == i){
            list2[index].status = true
            this.skill_id = list2[index].positionId
         }else{
            list2[i].status = false
         } 
      }
      //  console.log(list2,index)
    },
    edit(){
      let user_id = sessionStorage.getItem("id")
      let id = this.$route.query.id //要修改的技能id
     let skill_name = this.name //新技能名称
     let skill_id =  this.positionId //新技能id
     let skill_type = this.$route.query.ptype //技能类型

      this.$http.post("/user/v1/Position/userSkillDetail",{reqType:"userSkillDetail",user_id:user_id,skill_type:skill_type,skill_id:skill_id,skill_name:skill_name,mim_id:0,status:2,id:id})
      .then((res)=>{
        // console.log(res)
        res = JSON.parse(res.data)
        if(res.code == 0){
          Toast.success("编辑成功")
          setTimeout(()=>{
            this.$router.push("./onlineTag")
          },1000)
        }else{
          Toast.fail(res.msg)
          
        }
      })
      .catch((e)=>{console.log(e)})
      // console.log(user_id,id,skill_name,skill_id,skill_type)
    },
  },
  mounted(){
    //获取职位列表
     let type = this.$route.query.ptype
     this.showLoading = true
    this.$http.post("/user/v1/Position/positionList",{reqType:"positionList",pid:"",name:""})
    .then((res)=>{
        let res1 = JSON.parse(res.data)
        if(res1.code == 0){
          this.showLoading = false
          let allList = res1.data[type-1].children
          if(allList.length>0){
            allList.map((value)=>{
            if(value.children){
              value.children.map((item)=>{
                item.status = false
              })
            }
             this.allList = allList
            this.list2 = this.allList[0].children
          })
        }
        }else{
          this.showLoading = false
          Toast.fail(res1.msg)
        }
      
        
        
    })
    .catch((e)=>{console.log(e)})
    
  }
}
</script>
<style scoped>
.loading{
    position:absolute;
    top:50%;
    left:50%;
    margin-left:-0.2rem;
}
.container{
  position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
  }
 
.van-sidebar{
height:10rem;
overflow:hidden;
 overflow-y:scroll;
}
.rel{
  position: relative;
}
.next{
  position: absolute;
  bottom: 1.5rem;
  left:1rem;
  width: 4.01rem;
  height: 0.97rem;
  line-height: 0.97rem;
  background: linear-gradient(90deg, #FF4800 0%, #FC9E46 100%);
  border-radius: 0.16rem;
  text-align: center;

  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
}
.txtColor{
  color:#FF4800
}
.list{
    display:flex;
    flex-wrap: wrap;
    margin-top:0.46rem;
    margin-left:0.25rem;
}
.noactive{
height: 0.7rem;
line-height: 0.7rem;
background: #FFFFFF;
border-radius: 0.4rem;
border: 0.01rem solid #979797;
padding:0 0.5rem;
font-size: 0.28rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #979797;
margin-right:0.3rem;
margin-bottom:0.2rem;
}
.addtel{
  position:absolute;
  bottom:1rem;
  left:1.7rem;
  width: 4.01rem;
  height: 0.97rem;
  line-height: 0.97rem;
  background: linear-gradient(90deg, #FF4800 0%, #FC9E46 100%);
  border-radius: 0.16rem;
  text-align: center;

  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
}
.active{
  height: 0.7rem;
line-height: 0.7rem;
border-radius: 0.4rem;
border: 0.01rem solid #FF4800;
padding:0 0.5rem;
font-size: 0.28rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FF4800;
margin-right:0.3rem;
margin-bottom:0.2rem;
}


</style>